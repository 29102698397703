import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";
import Demo from "../views/Demo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/trydo",
    component: Demo,
    meta: {
      title: "Trydo || Creative Agency And Multipurpose Template",
    },
  },
  {
    path: "/",
    name: "SiberWeb",
    meta: {
      title: "Sibernetik Integra Data",
    },
    component: () =>
      import("../views/all-home-version/siber-home.vue"),
  },

  {
    path: "/about",
    name: "About",
    meta: {
      title: "About",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/main-demo",
    name: "MainDemo",
    meta: {
      title: "Main Demo || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/MainDemo.vue"),
  },
  {
    path: "/main-demo-dark",
    name: "MainDemoDark",
    meta: {
      title: "Main Demo Dark || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/MainDemoDark.vue"),
  },
  {
    path: "/creative-agency",
    name: "CreativeAgency",
    meta: {
      title: "Creative Agency || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/CreativeAgency.vue"),
  },
  {
    path: "/interactive-agency",
    name: "InterActiveAgency",
    meta: {
      title: "Interactive Agency || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/InterActiveAgency.vue"),
  },
  {
    path: "/digital-agency",
    name: "DigitalAgency",
    meta: {
      title: "Digital Agency || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/DigitalAgency.vue"),
  },
  {
    path: "/business",
    name: "Business",
    meta: {
      title: "Business || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/Business.vue"),
  },
  {
    path: "/corporate-business",
    name: "CorporateBusiness",
    meta: {
      title: "Corporate Business || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/CorporateBusiness.vue"),
  },
  {
    path: "/startup",
    name: "StartUp",
    meta: {
      title: "Start Up || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/StartUp.vue"),
  },
  {
    path: "/creative-portfolio",
    name: "CreativePortfolio",
    meta: {
      title: "Creative Portfolio || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/CreativePortfolio.vue"),
  },
  {
    path: "/minimal-portfolio",
    name: "MinimalPortfolio",
    meta: {
      title: "Minimal Portfolio || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/MinimalPortfolio.vue"),
  },
  {
    path: "/landing-home-particle",
    name: "LandingHomeParticle",
    meta: {
      title: "Landing Home Particle || VueJS Multipurpose Template",
    },
    component: () =>
      import("../views/all-home-version/LandingHomeParticle.vue"),
  },
  {
    path: "/landing-creative-agency",
    name: "LandingCreativeAgency",
    meta: {
      title: "Landing Creative Agency || VueJS Multipurpose Template",
    },
    component: () =>
      import("../views/all-home-version/LandingCreativeAgency.vue"),
  },
  {
    path: "/landing-interior",
    name: "LandingInterior",
    meta: {
      title: "Landing Interior || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/LandingInterior.vue"),
  },
  {
    path: "/landing-personal-portfolio",
    name: "LandingPersonalPortfolio",
    meta: {
      title: " Personal Portfolio Landing || VueJS Multipurpose Template",
    },
    component: () =>
      import("../views/all-home-version/LandingPersonalPortfolio.vue"),
  },
  {
    path: "/landing-personal-portfolio-02",
    name: "LandingPersonalPortfolioTwo",
    meta: {
      title: "Personal Portfolio Landing || VueJS Multipurpose Template",
    },
    component: () =>
      import("../views/all-home-version/LandingPersonalPortfolioTwo.vue"),
  },
  {
    path: "/personal-portfolio",
    name: "PersonalPortfolio",
    meta: {
      title: "Personal Portfolio || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/PersonalPortfolio.vue"),
  },
  {
    path: "/designer-portfolio",
    name: "DesignerPortfolio",
    meta: {
      title: "Designer Portfolio || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/DesignerPortfolio.vue"),
  },
  {
    path: "/studio-agency",
    name: "StudioAgency",
    meta: {
      title: "Studio Agency || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/StudioAgency.vue"),
  },
  {
    path: "/parallax-home",
    name: "ParallaxHome",
    meta: {
      title: "Parallax Home || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/ParallaxHome.vue"),
  },
  {
    path: "/service",
    name: "Service",
    meta: {
      title: "Service || VueJS Multipurpose Template",
    },
    component: () => import("../views/service/Service.vue"),
  },
  {
    path: "/service-details",
    name: "ServiceDetails",
    meta: {
      title: "Service Details || VueJS Multipurpose Template",
    },
    component: () => import("../views/service/ServiceDetails.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    meta: {
      title: "Blog || VueJS Multipurpose Template",
    },
    component: () => import("../views/blog/Blog.vue"),
  },
  {
    path: "/blog-details",
    name: "BlogDetails",
    meta: {
      title: "Blog Details || VueJS Multipurpose Template",
    },
    component: () => import("../views/blog/BlogDetails.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    meta: {
      title: "Portfolio || VueJS Multipurpose Template",
    },
    component: () => import("../views/portfolio/Portfolio.vue"),
  },
  {
    path: "/portfolio-details",
    name: "PortfolioDetails",
    meta: {
      title: "Portfolio Details || VueJS Multipurpose Template",
    },
    component: () => import("../views/portfolio/PortfolioDetails.vue"),
  },
  {
    path: "/team",
    name: "Team",
    meta: {
      title: "Team || VueJS Multipurpose Template",
    },
    component: () => import("../views/Team.vue"),
  },
  {
    path: "/button",
    name: "Button",
    meta: {
      title: "Button || VueJS Multipurpose Template",
    },
    component: () => import("../views/Button.vue"),
  },
  {
    path: "/pricing-plan",
    name: "PricingPlan",
    meta: {
      title: "Pricing Plan || VueJS Multipurpose Template",
    },
    component: () => import("../views/PricingPlan.vue"),
  },
  {
    path: "/accordion-with-tab",
    name: "AccordionWithTab",
    meta: {
      title: "Accordion With Tab || VueJS Multipurpose Template",
    },
    component: () => import("../views/AccordionWithTab.vue"),
  },
  {
    path: "/testimonial",
    name: "Testimonial",
    meta: {
      title: "Tesimonial || VueJS Multipurpose Template",
    },
    component: () => import("../views/Testimonial.vue"),
  },
  {
    path: "/column",
    name: "Column",
    meta: {
      title: "Column || VueJS Multipurpose Template",
    },
    component: () => import("../views/Column.vue"),
  },
  {
    path: "/list-style",
    name: "ListStyle",
    meta: {
      title: "List Style || VueJS Multipurpose Template",
    },
    component: () => import("../views/ListStyle.vue"),
  },
  {
    path: "/video-popup",
    name: "VideoPopup",
    meta: {
      title: "Video Popup || VueJS Multipurpose Template",
    },
    component: () => import("../views/VideoPopup.vue"),
  },
  {
    path: "/brand",
    name: "Brand",
    meta: {
      title: "Brand || VueJS Multipurpose Template",
    },
    component: () => import("../views/Brand.vue"),
  },
  {
    path: "/counter",
    name: "Counter",
    meta: {
      title: "Counter || VueJS Multipurpose Template",
    },
    component: () => import("../views/Counter.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "Contact || VueJS Multipurpose Template",
    },
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/gallery",
    name: "Gallery",
    meta: {
      title: "Gallery || VueJS Multipurpose Template",
    },
    component: () => import("../views/Gallery.vue"),
  },
  {
    path: "/progressbar",
    name: "Progressbar",
    meta: {
      title: "Progressbar || VueJS Multipurpose Template",
    },
    component: () => import("../views/Progressbar.vue"),
  },
  {
    path: "*",
    component: Missing,
    meta: {
      title: "404 || VueJS Multipurpose Template",
    },
  },
  
  // Page lain yang terpakai oleh web siber
  // {
  //   path: "/siber-web",
  //   name: "SiberWeb",
  //   meta: {
  //     title: "Sibernetik Integra Data",
  //   },
  //   component: () =>
  //     import("../views/all-home-version/siber-home.vue"),
  // },
  {
    path: "/contact-us",
    name: "ContactUs",
    meta: {
      title: "Contact Us || Sibernetik Integra Data",
    },
    component: () => import("../views/ContactSiber.vue"),
  },
  {
    path: "/article",
    name: "Article",
    meta: {
      title: "Article || Sibernetik Integra Data",
    },
    component: () => import("../views/ArticleVer2.vue"),
  },
  // {
  //   path: "/linkedin-page",
  //   name: "Linkedin-Page",
  //   meta: {
  //     title: "Linkedin Page || Sibernetik Integra Data",
  //   },
  //   component: () => import("../views/Linkedin-Page.vue"),
  // },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "Login || Sibernetik Integra Data",
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/generate-number/:name?",
    name: "Generate Number",
    meta: {
      title: "Generate Number || Sibernetik Integra Data",
    },
    component: () => import("../views/GenerateNoSurat.vue"),
  },
  {
    path: "/crew/:name?",
    name: "Digital Business Card",
    meta: {
      title: "Digital Business Card || Sibernetik Integra Data",
    },
    component: () => import("../views/Crew-id.vue"),
  },
  // {
  //   path: "/generate-number/user2-dashboard",
  //   name: "Generate Number",
  //   meta: {
  //     title: "Generate Number || Sibernetik Integra Data",
  //   },
  //   component: () => import("../views/GenerateNoSurat.vue"),
  // },
  // {
  //   path: "/generate-number/user3-dashboard",
  //   name: "Generate Number",
  //   meta: {
  //     title: "Generate Number || Sibernetik Integra Data",
  //   },
  //   component: () => import("../views/GenerateNoSurat.vue"),
  // },
  
  

  // Menu What we do (Services)
  {
    path: "/data-services",
    name: "Data Services",
    meta: {
      title: "Data Services || Sibernetik Integra Data",
    },
    // component: () => import("../views/DataServices.vue"),
  },
  {
    path: "/application-services",
    name: "Application Services",
    meta: {
      title: "Application Services || Sibernetik Integra Data",
    },
    component: () => import("../views/ApplicationServices.vue"),
  },
  {
    path: "/security-services",
    name: "Security Services",
    meta: {
      title: "Security Services || Sibernetik Integra Data",
    },
    component: () => import("../views/SecurityServices.vue"),
  },
  {
    path: "/managed-services",
    name: "Managed Services",
    meta: {
      title: "Managed Services || Sibernetik Integra Data",
    },
    // component: () => import("../views/ManagedServices.vue"),
  },
  // End of - Menu What we do (Services)

  // Menu What we do (Product)
  {
    path: "/datapipe",
    name: "Datapipe",
    meta: {
      title: "Datapipe || Sibernetik Integra Data",
    },
    component: () => import("../views/Datapipe.vue"),
  },
  {
    path: "/geopipe",
    name: "Geopipe",
    meta: {
      title: "Geopipe || Sibernetik Integra Data",
    },
    component: () => import("../views/Geopipe.vue"),
  },
  {
    path: "/securepipe",
    name: "Securepipe",
    meta: {
      title: "Securepipe || Sibernetik Integra Data",
    },
    component: () => import("../views/Securepipe.vue"),
  },
  {
    path: "/in-platform",
    name: "In-Platform",
    meta: {
      title: "In-Platform || Sibernetik Integra Data",
    },
    component: () => import("../views/InPlatform.vue"),
  },
  // End of - Menu What we do (Product)

  // Menu What we do (Solutions)
  {
    path: "/data-streaming",
    name: "Realtime Data Streaming & Processing",
    meta: {
      title: "Realtime Data Streaming & Processing || Sibernetik Integra Data",
    },
    // component: () => import("../views/DataStreaming.vue"),
  },
  {
    path: "/operational-data-store",
    name: "Operational Data Store & Mart",
    meta: {
      title: "Operational Data Store & Mart || Sibernetik Integra Data",
    },
    component: () => import("../views/LimitManagementSystem.vue"),
  },
  {
    path: "/limit-management-system",
    name: "Limit Management System",
    meta: {
      title: "Limit Management System || Sibernetik Integra Data",
    },
    component: () => import("../views/LimitManagementSystem.vue"),
  },
  {
    path: "/psre-hub",
    name: "PSrE Hub",
    meta: {
      title: "PSrE Hub || Sibernetik Integra Data",
    },
    component: () => import("../views/PsreHub.vue"),
  },
  {
    path: "/marine-surveillance-system",
    name: "Marine Surveillance System",
    meta: {
      title: "Marine Surveillance System || Sibernetik Integra Data",
    },
    component: () => import("../views/MarineSurveillanceSystem.vue"),
  },
  {
    path: "/air-traffic-surveillance",
    name: "Air Traffic Surveillance",
    meta: {
      title: "Air Traffic Surveillance || Sibernetik Integra Data",
    },
    component: () => import("../views/AirTrafficSurveillance.vue"),
  },
  {
    path: "/ground-asset-management",
    name: "Ground Asset Management",
    meta: {
      title: "Ground Asset Management || Sibernetik Integra Data",
    },
    component: () => import("../views/GroundAssetManagement.vue"),
  },
  {
    path: "/perspectives",
    name: "Perspectives",
    meta: {
      title: "Perspectives || Sibernetik Integra Data",
    },
    component: () => import("../views/Perspectives.vue"),
  },
  {
    path: "/news",
    name: "News",
    meta: {
      title: "News || Sibernetik Integra Data",
    },
    component: () => import("../views/News.vue"),
  },
  {
    path: "/events",
    name: "Event",
    meta: {
      title: "Events || Sibernetik Integra Data",
    },
    component: () => import("../views/Events.vue"),
  },
  {
    path: "/siberian",
    name: "Siberian",
    meta: {
      title: "Siberian || Sibernetik Integra Data",
    },
    component: () => import("../views/Siberian.vue"),
  },
  {
    path: "/solutions/data",
    name: "data",
    meta: {
      title: "Data || Sibernetik Integra Data",
    },
    component: () => import("../views/main-pages/solutions/Data.vue"),
  },
  {
    path: "/solutions/application",
    name: "application",
    meta: {
      title: "Application || Sibernetik Integra Data",
    },
    component: () => import("../views/main-pages/solutions/Application.vue"),
  },
  {
    path: "/solutions/security",
    name: "security",
    meta: {
      title: "Security || Sibernetik Integra Data",
    },
    component: () => import("../views/main-pages/solutions/Security.vue"),
  },
  {
    path: "/industries/banking-financial-services",
    name: "Banking & Financial Services",
    meta: {
      title: "Banking & Financial Services || Sibernetik Integra Data",
    },
    component: () => import("../views/main-pages/industries/BankingFinancialServices.vue"),
  },
  {
    path: "/industries/telecommunications",
    name: "Telecommunications",
    meta: {
      title: "Telecommunications || Sibernetik Integra Data",
    },
    component: () => import("../views/main-pages/industries/Telecommunications.vue"),
  },
  {
    path: "/industries/fast-moving-consumer-goods",
    name: "Fast Moving Consumer Goods",
    meta: {
      title: "Fast Moving Consumer Goods || Sibernetik Integra Data",
    },
    component: () => import("../views/main-pages/industries/FastMovingConsumerGoods.vue"),
  },
  {
    path: "/industries/governments-agencies-military-defense-police",
    name: "Selected Governments Agencies, Military, Defense & Police",
    meta: {
      title: "Governments Agencies Military Defense Police || Sibernetik Integra Data",
    },
    component: () => import("../views/main-pages/industries/GovernmentsAgenciesMilitaryDefensePolice.vue"),
  },
  {
    path: "/about/news&event",
    name: "news&event",
    meta: {
      title: "News & Event || Sibernetik Integra Data",
    },
    component: () => import("../views/main-pages/about/News&event.vue"),
  },
  // {
  //   path: "/siber-career",
  //   name: "Career",
  //   meta: {
  //     title: "Sibernetik Career || Sibernetik Integra Data",
  //   },
  //   component: () => import("../views/Career.vue"),
  // },
  {
    path: "/siber-career",
    name: "Career",
    meta: {
      title: "Sibernetik Career || Sibernetik Integra Data",
    },
    component: () => import("../views/LinkedinPage.vue"),
  },
  {
    path: "/uploader",
    name: "uploader",
    meta: {
      title: "Hidden Feature || Sibernetik Integra Data",
    },
    component: () => import("../views/hidden-features/Uploader.vue"),
  },

  // End of - Menu What we do (Solutions)
  
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    // console.log(to, savedPosition)
    let scrollTo = 0;

    if (to.hash) {
      // scrollTo = to.hash;
      return {
        selector: to.hash,
        offset: { x: 0, y: 0 }, // Adjust the offset if needed (64 is an example offset for a fixed header)
      }
    } else if (savedPosition) {
      // scrollTo = savedPosition.y;
      return savedPosition
    } else {
      return { x: 0, y: 0 };
    }

    // return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
