<template>
  <v-app id="inspire">
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        color="white"
        fixed
        width="50"
        height="50"
        bottom
        right
        class="mr-1"
        @click="toTop"
        style="bottom: 30px"
      >
        <v-icon dark>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- <v-fab-transition>
      <v-btn
        fab
        fixed
        dark
        color="#dd8732"
        bottom
        right
        style="bottom: 30px"
        to="/contact-us"
      >
        <v-icon dark>
          mdi-chat-processing
        </v-icon>
      </v-btn>
    </v-fab-transition> -->
    
    <!-- <v-btn-toggle
      v-model="toggle_exclusive"
      mandatory
      top
      light
      style="flex-direction: column; top: 150px; right: 0px; z-index: 77; position: fixed; background-color: transparent;"
    >
      <v-btn style="background-color: #dd8732;">
        <v-icon style="color:#fff">mdi-account-tie</v-icon>
      </v-btn>
      <v-btn style="background-color: #dd8732;">
        <v-icon style="color:#fff">mdi-monitor</v-icon>
      </v-btn>
      <v-btn style="background-color: #dd8732;">
        <v-icon style="color:#fff">mdi-email-check-outline</v-icon>
      </v-btn>
    </v-btn-toggle> -->

  </v-app>
</template>

<script>
export default {
  data: () => ({
    fab: false,
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.v-btn--is-elevated.v-btn--fab {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  height: 30px;
  font-size: 30px;
  width: 30px;
}
.v-btn--fab.v-btn--fixed {
  z-index: 99;
}
.fa-angle-up {
  display: block;
  line-height: 48px;
  font-size: 22px;
  font-weight: 600;
  color: #3b4950;
}
.v-btn--absolute.v-btn--bottom,
.v-btn--fixed.v-btn--bottom {
  bottom: 60px;
}

.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: 20px;
}
.v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
    z-index: 7;
  }
</style>
