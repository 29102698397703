<template>
  <div class="error-page-inner bg_color--4" style="width: 100vw; height: 100vh; position: relative;">
    <v-container class="mt-10" style="width: 100%; height: 100vh;">
      <v-row>
        <v-col>
          <img src="../assets/images/logo/SIDlogo2.png" />
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12">
        </v-col>
      </v-row> -->
    </v-container>
    <div class="inner" style="top: 50%; left: 50%; position:absolute; transform: translate(-50%, -50%);">
      <h1 class="heading-title theme-gradient">404!</h1>
      <h3 class="sub-title">Page not found</h3>
      <span>The page you were looking for could not be found.</span>
      <div class="error-button">
        <router-link class="rn-button-style--2 btn_solid" to="/">Back To Homepage</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import FooterTwo from "../components/footer/FooterTwo";
export default {
  components: {
    Header,
    FooterTwo,
  },
  data() {
    return {};
  },
};
</script>
